import React from "react";

import Link from 'components/Link'

const ReadMore = ({ children, limit }) => {
	const text = children;
	const [isReadMore, setIsReadMore] = React.useState(true);
	const toggleReadMore = () => {
		setIsReadMore(!isReadMore);
	};
	return (
		<p className="text">
			{isReadMore ? text.slice(0, limit) : text}
			<Link onClick={toggleReadMore} to={`#`} className="read-or-hide">
				{isReadMore ? "...Read More" : " Show Less"}
			</Link>
		</p>
	);
};

export default ReadMore;