import * as React from "react"
import { graphql } from 'gatsby'

import DataProvider from 'providers/data';

import Layout from 'templates/Layout';
import Content from 'templates/Content';
import CareerGrid from 'global/CareerGrid';



const CareerOpportunity = (props) => {

	const { edges: pageEdges } = props.data.allContentPage;
	const pageNodes = pageEdges.map(({ node }) => node);
	let page = pageNodes[0];

	if ((props.providerResponse.data.length > 0 || !!page !== false) && props.providerStatusText === 'LOADED') {
		page = props.providerResponse.data[0];
	}

	return (
		<Layout>
			<Content {...page} />
			<CareerGrid />
		</Layout>
	)
}

const getData = (props) => <DataProvider liveRefresh={props.params.liveRefresh} type="ContentPage" apiParams={{ uri: "/hospitality-employment-hub/", approved: true, archived: false, deleted: false }}><CareerOpportunity {...props} /></DataProvider>
export default getData;

export { Head } from 'components/templates/Head';

export const query = graphql`
{
	allContentPage(
		filter: {uri: {eq: "/hospitality-employment-hub/"}, approved: {eq: true}, archived: {eq: false}, deleted: {eq: false}}
	  ) {
		edges {
		  node {
			pageTitle
			meta
			content {
			  main
			}
			headlineBody
			headlineTitle
			headlineImage
			headlineType
			headlineBgColor
			headlineColor
			quicklinks
			formID
		  }
		}
	}
}
`