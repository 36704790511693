import React from "react";
import Slider from "react-slick";
import { graphql, StaticQuery } from "gatsby";

import Link from 'components/Link'

import DataProvider from "providers/data";
import FadeIn from 'components/lib/FadeIn'
import ReadMore from 'components/lib/ReadMore'

const CareerGrid = props => {

	const settings = {
		arrows: false,
		dots: false,
		infinite: true,
		speed: 500,
		autoplay: true,
		autoplaySpeed: 15000,
		slidesToShow: 1,
		slidesToScroll: 1,
		centerMode: true,
	};

	return (
		<StaticQuery
			query={graphql`
              query AllCareerOpportunity {
                allCareerOpportunity(filter: {approved: {eq: true}, archived: {eq: false}, deleted: {eq: false}}) {
                  edges {
                    node {
                      title
                      summary
                      link
                    }
                  }
                }
              }
            `}
			render={(data) => {
				const { edges } = data.allCareerOpportunity;
				let opportunities = edges.map(({ node }) => node);
				if ((props.providerResponse.data.length > 0 || !!opportunities !== false) && props.providerStatusText === "LOADED") {
					opportunities = props.providerResponse.data;
				}

				if (opportunities.length === 0) return null;

				return (
					<section className="career-grid">
						<div className="grid-container">
							<div className="grid-x grid-margin-x">
								<div className="cell text-center"><h2 className="header">Employment Opportunities</h2></div>
							</div>
							<div className="grid-x grid-margin-x small-up-1 medium-up-2 hide-for-small-only">
								{opportunities.map((opportunity, index) => {
									return (
										<FadeIn direction=" " className="cell card-outer-wrapper" key={index}>
											<div className="card">
												<div className="card-section">
													<h3>{opportunity.title}</h3>
													<ReadMore limit={200}>
														{opportunity.summary}
													</ReadMore>
												</div>
												<div className="card-divider">
													<Link to={opportunity.link} className="button white no-hover">View Position</Link>
												</div>
											</div>
										</FadeIn>
									)
								})}
							</div>
						</div>
						<div className="grid-x show-for-small-only">
							<div className="cell">
								<Slider {...settings}>
									{opportunities.map((opportunity, index) => {
										return (
											<div className="slide" key={index}>
												<div className="card">
													<div className="card-section">
														<h3>{opportunity.title}</h3>
														<ReadMore limit={200}>
															{opportunity.summary}
														</ReadMore>
													</div>
													<div className="card-divider">
														<Link to={opportunity.link} className="button white no-hover">View Position</Link>
													</div>
												</div>
											</div>
										)
									})}
								</Slider>
							</div>
						</div>
					</section>
				)
			}}
		/>
	)
}

const getData = (props) => <DataProvider liveRefresh={true} type="CareerOpportunity" apiParams={{ approved: true, archived: false, deleted: false }}><CareerGrid {...props} /></DataProvider>
export default getData;